<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-21 16:09:47
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-02-03 12:04:29
-->
<template>
  <div class="ArtItem" @click="corresponddetail">
    <!-- 标题信息 -->
    <div class="art-title theme-color">{{ defaultItem.articleName || $t('Detail.noName') }}</div>
    <div class="art-remark">{{ defaultItem.articleDescribe }}</div>
    <!-- 作者信息 -->
    <div class="updateTime">{{ fomateDateTime }}</div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "ArtItem",
  props: {
    defaultItem: {
      type: Object,
    },
    usageCategoryId: {},
    categoryName: {},
    GroupName: {},
    usageGroupId: {},
  },
  created() {},
  mounted() {},
  computed: {
    fomateDateTime() {
      return dayjs(this.defaultItem.updateTime).format("YYYY/MM/DD HH:mm:ss");
    },
  },
  methods: {
    corresponddetail() {
      let articleId = this.defaultItem.articleId;
      this.$router.push({
        name: "Detail",
        params: this.$route.params,
        query: {
          articleId,
          usageCategoryId: this.usageCategoryId,
          usageGroupId: this.usageGroupId,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.ArtItem {
  cursor: pointer;
  padding: 25px 32px;
  background: rgba(0, 0, 0, 0);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  .art-title {
    font-family: PingFangSC-Regular;
    font-size: 19px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 4px;
    &:hover {
      text-decoration: underline;
    }
  }
}
.art-remark {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 24px;
  font-weight: 400;
}

.updateTime {
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 400;
  margin-top: 12px;
}
</style>
