<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-21 15:28:52
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-03 10:51:09
-->
<template>
  <div class="list-tep">
    <!-- 组别信息 -->
    <div class="type-Info">
      <div class="icon">
        <img :src="categoryDetailData.categoryIcon" alt srcset />
      </div>
      <div class="info-detail">
        <div class="type-name">{{ categoryDetailData.categoryName }}</div>
        <div class="type-remark">{{ categoryDetailData.categoryDescribe }}</div>
        <div class="type-art-num">{{ $t("Collections.total") + countArticle + $t("Collections.nums") }}</div>
      </div>
    </div>
    <!-- 未分组 -->
    <div class="no-group" v-if="defaultGroup.articles">
      <ArtItem
        v-for="item in defaultGroup.articles"
        :usageCategoryId="usageCategoryId"
        :key="item.id"
        :defaultItem="item"
        :categoryName="categoryDetailData.categoryName"
        :GroupName="item.usageGroupName"
        :usageGroupId="defaultGroup.usageGroupId"
      ></ArtItem>
    </div>
    <!-- 已经分组 -->
    <div class="has-group">
      <div class="group" v-for="items in customGroup" :key="items.id">
        <div class="group-name">{{ items.usageGroupName }}</div>
        <div class="art-list">
          <ArtItem
            v-for="item in items.articles"
            :key="item.id"
            :usageCategoryId="usageCategoryId"
            :defaultItem="item"
            :categoryName="categoryDetailData.categoryName"
            :GroupName="items.usageGroupName"
            :usageGroupId="items.usageGroupId"
          ></ArtItem>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArtItem from "./modulecope/ArtItem.vue";
export default {
  name: "ListTep",
  components: { ArtItem },
  props: {
    usageCategoryId: {},
    categoryDetailData: {},
    customGroup: {},
    defaultGroup: {},
    countArticle: {},
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},

  methods: {},
};
</script>
<style scoped lang="scss">
.list-tep {
  background: rgb(248, 249, 250);
  border-radius: 8px;
  padding: 32px;
}
.type-Info {
  display: flex;
  .icon {
    width: 100px;
    height: 100px;
    padding: 29px;
    box-sizing: border-box;
  }
  .info-detail {
    flex: 1;
    margin-left: 24px;
    .type-name {
      font-family: PingFangSC-Medium;
      font-size: 24px;
      color: #000000;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .type-remark {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.8);
      line-height: 24px;
      font-weight: 400;
    }
    .type-num {
      font-family: PingFangSC-Regular;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.4);
      letter-spacing: -0.17px;
      font-weight: 400;
    }
  }
}
.no-group {
  margin: 24px 0;
  border-radius: 8px;
  background: #fff;

  box-shadow: 0 2px 24px 0 rgb(0 77 193 / 8%);
  &::v-deep {
    .ArtItem:last-child {
      box-shadow: 0 0 0;
    }
  }
}
.has-group {
  .group {
    margin: 24px 0;
    .group-name {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: -0.2px;
      font-weight: 400;
      line-height: 48px;
    }
    .art-list {
      border-radius: 8px;
      background: #fff;
      box-shadow: 0 2px 24px 0 rgb(0 77 193 / 8%);
      &::v-deep {
        .ArtItem:last-child {
          box-shadow: 0 0 0;
        }
      }
    }
  }
}
.type-art-num {
  margin-top: 17px;
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: -0.17px;
  font-weight: 400;
}
</style>
