<!--
 * @FileDescription: asdasd
 * @Author: ruokun Yin
 * @Date: 2022-11-17 15:36:27
 * @LastEditors: zhuxin xu
 * @LastEditTime: 2023-02-02 17:11:16
-->
<template>
  <div class="group_list">
    <div class="art-list">
      <div class="core">
        <Breadcrumb :data="Breadcrumbdata" />
      </div>
      <ListTep
        :categoryDetailData="categoryDetailData"
        :defaultGroup="defaultGroup"
        :usageCategoryId="usageCategoryId"
        :customGroup="customGroup"
        :countArticle="countArticle"
      ></ListTep>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import ListTep from "./module/ListTep.vue";
export default {
  components: { ListTep, Breadcrumb },
  data() {
    return {
      expand: true,
      usageCategoryId: null,
      defaultGroup: {},
      customGroup: {},
      categoryDetailData: {},
      Breadcrumbdata: [],
      countArticle: 0,
    };
  },

  created() {},
  mounted() {
    this.usageCategoryId = this.$route.query.id;
    this.getUsageDetail();
  },
  computed: {
    language() {
      return this.$store.state.defaultlanguage;
    },
  },
  watch: {
    language() {
      this.getUsageDetail();
    },
  },
  methods: {
    getUsageDetail() {
      var params = {
        usageCategoryId: this.usageCategoryId,
        categoryLanguage: this.language,
      };
      this.$api.getUsageDetail(params).then((res) => {
        if (res.code == 200) {
          this.categoryDetailData = res.data;
          this.defaultGroup = res.data.defaultGroup;
          this.customGroup = res.data.customGroup.filter((item) => {
            return item.articles[0];
          });
          var customGroupCountArticleNum = 0;
          res.data.customGroup.forEach((item) => {
            customGroupCountArticleNum += item.countArticle;
          });
          this.countArticle =
            customGroupCountArticleNum + this.defaultGroup.countArticle;
          this.Breadcrumbdata = [
            {
              name: res.data.categoryName,
              path: "",
            },
          ];
        } else {
          // this.$message({
          //   type: "error",
          //   message: res.msg,
          //   duration: 1000,
          //   showClose: true,
          // });
          this.$router.replace({
            name: "Error",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.art-list {
  max-width: 1200px;
  min-height: 77vh;
  padding-left: 40px;
  padding-right: 40px;
  margin: 30px auto;
  .core {
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 24px;
    .breadcrumb {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.4);
      letter-spacing: -0.2px;
      font-weight: 400;
    }
    .last {
      color: rgba(0, 0, 0, 0.8);
    }
    span {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin: 0 4px;
    }
  }
}
</style>
